/* eslint-disable max-len */
import * as yup from "yup";
import { convertDimensionsMeasure, convertWeightMeasure } from "../Extend/ConvertToDefaultValues";

const LineItemSchema = yup.object().shape({
  hazardous_materials: yup.bool(),
  linear_feet: yup.string(),
  name: yup.string(),
  description: yup.string().required(),
  nmfc_class: yup.string().required().nullable(),
  dimension_units: yup.string().required(),
  height: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("quoteMode", {
      is: "all-options",
      then: yup.string().test("height-limit", (value, { createError, parent }) => {
        const height = parseFloat(value);
        if (height > 125) {
          const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 125);
          return createError({
            message: `You have exceeded the height limit. The maximum height allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "ltl",
      then: yup.string().test("height-limit", (value, { createError, parent }) => {
        const height = parseFloat(value);
        if (height > 96) {
          const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 96);
          return createError({
            message: `You have exceeded the height limit. The maximum height allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "expedited",
      then: yup.string().test("height-limit", (value, { createError, parent }) => {
        const height = parseFloat(value);
        if (height > 98) {
          const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 98);
          return createError({
            message: `You have exceeded the height limit. The maximum height allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "flatbed",
      then: yup.string().test("height-limit", (value, { createError, parent }) => {
        const height = parseFloat(value);
        if (height > 125) {
          const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 125);
          return createError({
            message: `You have exceeded the height limit. The maximum height allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    }),
  width: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("quoteMode", {
      is: "all-options",
      then: yup.string().test("width-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 101);
        if (width > 101) {
          return createError({
            message: `You have exceeded the width limit. The maximum width allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "ltl",
      then: yup.string().test("width-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 96);
        if (width > 96) {
          return createError({
            message: `You have exceeded the width limit. The maximum width allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "expedited",
      then: yup.string().test("width-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 98);
        if (width > 98) {
          return createError({
            message: `You have exceeded the width limit. The maximum width allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "flatbed",
      then: yup.string().test("width-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 101);
        if (width > 101) {
          return createError({
            message: `You have exceeded the width limit. The maximum width allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    }),
  length: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("quoteMode", {
      is: "all-options",
      then: yup.string().test("length-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 636);
        if (width > 636) {
          return createError({
            message: `You have exceeded the length limit. The maximum length allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "ltl",
      then: yup.string().test("length-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 193);
        if (width > 193) {
          return createError({
            message: `You have exceeded the length limit. The maximum length allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "expedited",
      then: yup.string().test("length-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 312);
        if (width > 312) {
          return createError({
            message: `You have exceeded the length limit. The maximum length allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "flatbed",
      then: yup.string().test("length-limit", (value, { createError, parent }) => {
        const width = parseFloat(value);
        const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 636);
        if (width > 636) {
          return createError({
            message: `You have exceeded the length limit. The maximum length allowed is ${limitResult}(${parent.user_dimension_units}).`,
          });
        }
        return true;
      }),
    }),
  weight_units: yup.string().required(),
  weight: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("quoteMode", {
      is: "all-options",
      then: yup.string().test("weight-limit", (value, { createError, parent }) => {
        const unitsHandling = parseFloat(parent.handling_unit_count) || 1;
        const weight = parseFloat(value);
        const limitResult = convertWeightMeasure(parent.user_weight_units, 46000);
        if (weight > 46000 * unitsHandling) {
          return createError({
            message: `The maximum weight allowed is ${limitResult}(${parent.user_weight_units}) per unit.`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "ltl",
      then: yup.string().test("weight-limit", (value, { createError, parent }) => {
        const unitsHandling = parseFloat(parent.handling_unit_count) || 1;
        const weight = parseFloat(value);
        const limitResult = convertWeightMeasure(parent.user_weight_units, 5000);
        if (weight > 5000 * unitsHandling) {
          return createError({
            message: `The maximum weight allowed is ${limitResult}(${parent.user_weight_units}) per unit.`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "expedited",
      then: yup.string().test("weight-limit", (value, { createError, parent }) => {
        const unitsHandling = parseFloat(parent.handling_unit_count) || 1;
        const weight = parseFloat(value);
        const limitResult = convertWeightMeasure(parent.user_weight_units, 9800);
        if (weight > 9800 * unitsHandling) {
          return createError({
            message: `The maximum weight allowed is ${limitResult}(${parent.user_weight_units}) per unit.`,
          });
        }
        return true;
      }),
    })
    .when("quoteMode", {
      is: "flatbed",
      then: yup.string().test("weight-limit", (value, { createError, parent }) => {
        const unitsHandling = parseFloat(parent.handling_unit_count) || 1;
        const weight = parseFloat(value);
        const limitResult = convertWeightMeasure(parent.user_weight_units, 46000);
        if (weight > 46000 * unitsHandling) {
          return createError({
            message: `The maximum weight allowed is ${limitResult}(${parent.user_weight_units}) per unit.`,
          });
        }
        return true;
      }),
    }),
  handling_units: yup.string().required(),
  handling_unit_count: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    ),

  user_weight: yup.string(),
  user_weight_units: yup.string(),
  user_length: yup.string(),
  user_width: yup.string(),
  user_height: yup.string(),
  user_dimension_units: yup.string(),
  quoteMode: yup.string(),
});

export default LineItemSchema;
